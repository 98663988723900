import { FC, useContext } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import EyeIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import React from 'react';
import ApproveIcon from '../../assets/approve-icon.svg';
import RejectIcon from '../../assets/reject-icon.svg';
import {
  AlertMsgContext,
  AlertMsgType,
} from '../../mir-styles/src/providers/AlertMsgProvider';
import StatusChip from './StatusChip';
import { ExtraFileType, FileType } from '../../providers/TestsProvider';
import Loading from '../../mir-styles/src/components/Loading/Loading';
import { formatDate } from '../../utils/dateFormat';

interface ListItemProps {
  labelId: string;
  result: FileType;
  checked: FileType[];
  showPreview: boolean;
  filesToApprove: any;
  previewFileName: string;
  handleToggle: (file: FileType) => any;
  setShowPreview: (showPreview: boolean) => any;
  approveRejectFile: (filename: string, approve: boolean) => void;
  setPreviewFileName: (previewFileName: string) => void;
  setPreviewFileUiid: (previewFileUiid: string) => void;
}

const ClassifierListItem: FC<ListItemProps> = ({
  labelId,
  result,
  checked,
  showPreview,
  filesToApprove,
  previewFileName,
  handleToggle,
  setShowPreview,
  approveRejectFile,
  setPreviewFileName,
  setPreviewFileUiid,
}) => {
  //Contexts
  const { updateAlertMsg } = useContext(AlertMsgContext) as AlertMsgType;

  //UseStates
  const [open, setOpen] = React.useState(false);
  const [arrowRotation, setArrowRotation] = React.useState(0);
  const [hover, setHover] = React.useState(false);
  const [hoveredItemIdx, setHoveredItemIdx] = React.useState(0);

  const handleClick = () => {
    setOpen(!open);

    if (arrowRotation === 0) {
      setArrowRotation(180);
    } else {
      setArrowRotation(0);
    }
  };

  let totalSizeCsvs: number = 0;
  result.extra_files.forEach((ef) => (totalSizeCsvs += ef.file_size));

  /*
    This function shows a snackbar just one time per session, letting the user know that
    the preview window blocks the page scrolling.
  */
  const showInfoSnackbar = () => {
    const showToast =
      sessionStorage.getItem('showPreviewToast') !== null
        ? sessionStorage.getItem('showPreviewToast')
        : 'true';

    showToast === 'true' &&
      updateAlertMsg({
        severity: 'info',
        message: 'To continue scrolling the page, close the Preview window.',
        open: true,
      });
  };

  return (
    <>
      <ListItem
        key={result.test_id}
        sx={{
          pl: '0px',
          border: open ? '1px solid var(--MirNeutral09)' : '',
          borderRadius: open ? '8px' : '',
        }}
      >
        {/* ARROW BUTTON */}
        <Button sx={{ minWidth: '0px', mr: '18px' }} onClick={handleClick}>
          <ExpandMore
            sx={{
              color: 'var(--MirNeutral06)',
              transition: '.2s cubic-bezier(.785,.135,.15,.86) 0s',
              transform: `rotate(${arrowRotation}deg)`,
            }}
          />
        </Button>

        {/* CHECKBOX */}
        <ListItemIcon onClick={handleToggle(result)}>
          <Checkbox
            edge="start"
            checked={checked.map((obj) => obj.test_id).indexOf(result.test_id) !== -1}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': labelId }}
            className="listItem__name"
          />
        </ListItemIcon>

        {/* TEXT */}
        <ListItemText
          primary={result.test_id}
          secondary={`Last modified: ${formatDate(
            new Date(result.createdAt)
          )}    |   Total size: ${totalSizeCsvs} bytes`}
          primaryTypographyProps={{
            color:
              result.approved || result.approved === false
                ? 'rgba(0, 0, 0, 0.54)'
                : 'var(--MirPrimary03)',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'pre-wrap',
          }}
          secondaryTypographyProps={{
            color:
              result.approved || result.approved === false
                ? 'rgba(0, 0, 0, 0.54)'
                : 'var(--MirNeutral06)',
          }}
        />

        {/* CHIPS */}
        {result.approved && <StatusChip type="approved" />}
        {result.approved === false && <StatusChip type="rejected" />}

        {/* APPROVE AND REJECT BUTTONS */}
        {result.approved === undefined &&
          (filesToApprove?.[result.test_id] ? (
            <Box sx={{ mr: '25px' }}>
              <Loading />
            </Box>
          ) : (
            <>
              <Tooltip title="Reject files">
                <Button
                  disabled={result.approved || result.approved === false}
                  onClick={() => {
                    approveRejectFile(result.test_id, false);
                    setOpen(false);
                    setArrowRotation(0);
                  }}
                  sx={{
                    minWidth: '24px',
                    width: '35px',
                    height: '35px',
                    borderRadius: '50px',
                  }}
                >
                  <img src={RejectIcon} alt="File" />
                </Button>
              </Tooltip>
              <Tooltip title="Approve files">
                <Button
                  disabled={result.approved || result.approved === false}
                  onClick={() => {
                    approveRejectFile(result.test_id, true);
                    setOpen(false);
                    setArrowRotation(0);
                  }}
                  sx={buttonStyle}
                >
                  <img src={ApproveIcon} alt="File" />
                </Button>
              </Tooltip>
            </>
          ))}
      </ListItem>

      <Divider />

      {/* DROPDOWN */}
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List>
          {result.extra_files.map((file: ExtraFileType, idx: number) => {
            return (
              <ListItemButton
                key={idx}
                sx={{
                  pl: '90px',
                  backgroundColor:
                    showPreview && previewFileName === file.filename
                      ? 'var(--MirNeutral02)'
                      : '',
                }}
                onClick={() => {
                  setPreviewFileName(file.filename);
                  setPreviewFileUiid(result.testUUID!);
                  setShowPreview(true);
                  showInfoSnackbar();
                  sessionStorage.setItem('showPreviewToast', 'false');
                }}
                onMouseOver={() => {
                  setHover(true);
                  setHoveredItemIdx(idx);
                }}
                onMouseOut={() => {
                  setHover(false);
                  setHoveredItemIdx(0);
                }}
              >
                <ListItemText
                  primary={file.filename.replace(result.testUUID!, '')}
                  secondary={`Size: ${file.file_size} bytes`}
                  primaryTypographyProps={{
                    color: 'var(--MirPrimary03)',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'pre-wrap',
                  }}
                  secondaryTypographyProps={{ color: 'var(--MirNeutral06)' }}
                />

                <Tooltip title="Preview">
                  <IconButton edge="end" sx={iconBtnStyle}>
                    <EyeIcon
                      sx={{
                        color:
                          showPreview && previewFileName === file.filename
                            ? 'var(--MirPrimary01)'
                            : hover && idx === hoveredItemIdx
                            ? '#438EFF '
                            : 'var(--MirNeutral06)',
                        mx: '20px',
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </ListItemButton>
            );
          })}
        </List>
      </Collapse>
    </>
  );
};

const buttonStyle = {
  minWidth: '24px',
  width: '35px',
  height: '35px',
  borderRadius: '50px',
  marginLeft: '26px',
  marginRight: '10px',
};

const iconBtnStyle = { width: '50px', height: '50px', mr: '4px' };

export default ClassifierListItem;
