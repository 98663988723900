import { FC } from 'react';
import {
  Button,
  Checkbox,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import React from 'react';
import StatusChip from './StatusChip';
import { ExtraFileType, ReportGroupType } from '../../providers/TestsProvider';
import { formatDate } from '../../utils/dateFormat';

interface ListItemProps {
  labelId: string;
  result: ReportGroupType;
  checked: ReportGroupType[];
  handleToggle: (value: ReportGroupType) => void;
}

const ReporterListItem: FC<ListItemProps> = ({
  labelId,
  result,
  checked,
  handleToggle,
}) => {
  //UseStates
  const [open, setOpen] = React.useState(false);
  const [arrowRotation, setArrowRotation] = React.useState(0);

  const handleClick = () => {
    setOpen(!open);
    if (arrowRotation === 0) {
      setArrowRotation(180);
    } else {
      setArrowRotation(0);
    }
  };

  let totalSizeCsvs: number = 0;

  result.reports && result.reports.forEach((rep) => (totalSizeCsvs += rep.file_size));

  let isNew = false;

  return (
    <>
      <ListItem
        key={result.test_id}
        sx={{
          pl: '0px  ',
          border: open ? '1px solid var(--MirNeutral09)' : '',
          borderRadius: open ? '8px' : '',
        }}
      >
        <Button sx={{ minWidth: '0px', mr: '18px' }} onClick={handleClick}>
          <ExpandMore
            sx={{
              color: 'var(--MirNeutral06)',
              transition: '.2s cubic-bezier(.785,.135,.15,.86) 0s',
              transform: `rotate(${arrowRotation}deg)`,
            }}
          />
        </Button>

        <ListItemIcon onClick={() => handleToggle(result)}>
          <Checkbox
            edge="start"
            checked={checked.map((obj) => obj.test_id).indexOf(result.test_id) !== -1}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': labelId }}
            className="listItem__name"
            onClick={() => handleToggle(result)}
          />
        </ListItemIcon>

        <ListItemText
          primary={result.test_id}
          secondary={`Last modified: ${formatDate(
            new Date(result.createdAt)
          )}    |   Total size: ${totalSizeCsvs} bytes`}
        />
        {isNew && <StatusChip type="new" />}
      </ListItem>
      <Divider />

      {/* DROPDOWN */}

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List>
          {result.reports &&
            result.reports.map((file: ExtraFileType, idx: number) => {
              const now = new Date().getTime();
              const dBDate = new Date(file.createdAt).getTime();

              const diff = Math.floor((now - dBDate) / 60000);

              if (diff < 5) {
                isNew = true;
              }

              return (
                <ListItem key={idx} sx={{ pl: '90px' }}>
                  <ListItemText
                    primary={file.filename.replace(result.uuid!, '')}
                    secondary={`Last modified: ${formatDate(
                      new Date(file.createdAt)
                    )} | Size: ${file.file_size} bytes`}
                    primaryTypographyProps={{
                      color: 'var(--MirPrimary03)',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'pre-wrap',
                    }}
                    secondaryTypographyProps={{
                      color: 'var(--MirNeutral06)',
                    }}
                  />
                </ListItem>
              );
            })}
        </List>
      </Collapse>
    </>
  );
};

export default ReporterListItem;
