import { FC, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import NotAuthorizedImage from '../../assets/mir-401-img.png';
import Loading from '../Loading/Loading';
import './logout.css';

interface LogoutProps {
  notAuthorized?: boolean;
}

const Logout: FC<LogoutProps> = ({ notAuthorized }) => {
  const { oktaAuth } = useOktaAuth();

  useEffect(() => {
    async function logout() {
      // window.opener = null;
      // window.open('', '_self');
      // window.close();
      const tokens = await oktaAuth.tokenManager.getTokens();
      await oktaAuth.revokeAccessToken(tokens.accessToken);
      await oktaAuth.revokeRefreshToken(tokens.refreshToken);
      oktaAuth.tokenManager.clear();
      // await oktaAuth.signOut({ clearTokensBeforeRedirect: true, postLogoutRedirectUri: process.env.REACT_APP_OKTA_URL })
      const a = document.createElement('a');
      a.href = process.env.REACT_APP_OKTA_URL + '';
      a.click();
    }
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="logout">
      {!notAuthorized ? (
        <>
          <Loading />
          <h1>Logging out of the Classifier app...</h1>
        </>
      ) : (
        <>
          <img src={NotAuthorizedImage} alt="Error 401 Not Authorized" />
          <h2>Authentication credentials were missing or incorrect</h2>
        </>
      )}

      <h3>You will be redirected to the Okta dashboard shortly</h3>
    </div>
  );
};

export default Logout;
