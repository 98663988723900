import { FC } from 'react';
import { Box, Button, Modal } from '@mui/material';
import MirButton from '../../mir-styles/src/components/MirButton/MirButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

interface OverwriteFileModalProps {
  fileName: string;
  isUploadingOverwrite: boolean;
  showOverwriteModal: boolean;
  cancel: () => void;
  overwriteFile: () => void;
  setShowOverwriteModal: (showOverwriteModal: boolean) => void;
}

const OverwriteFileModal: FC<OverwriteFileModalProps> = ({
  fileName,
  isUploadingOverwrite,
  showOverwriteModal,
  cancel,
  overwriteFile,
  setShowOverwriteModal,
}) => {
  return (
    <Modal
      open={showOverwriteModal}
      onClose={() => {
        setShowOverwriteModal(false);
        cancel();
      }}
    >
      <Box sx={containerStyle}>
        <div className="closeBtnOW__container">
          <Button
            sx={closeButtonStyle}
            onClick={() => {
              setShowOverwriteModal(false);
              cancel();
            }}
          >
            <CloseRoundedIcon sx={{ color: 'var(--MirNeutral06)' }} />
          </Button>
        </div>
        <div className="bodyOW__container">
          <span>
            The file <p className="filenameOW">{fileName}</p> already exists.
          </span>
          <p>Do you want to upload and overwrite it?</p>
          <div className="buttonsOW__container">
            <MirButton isOutlined={true} onClick={cancel} padding="12px 32px">
              <p>Cancel</p>
            </MirButton>
            <MirButton
              onClick={overwriteFile}
              isLoading={isUploadingOverwrite}
              padding="12px 32px"
              isDisabled={isUploadingOverwrite}
            >
              {isUploadingOverwrite ? <p>Uploading</p> : <p>Upload</p>}
            </MirButton>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

const containerStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '527px',
  height: '240px',
  backgroundColor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  p: '10px',
};

const closeButtonStyle = {
  minWidth: '24px',
  width: '35px',
  height: '35px',
  borderRadius: '50px',
};

export default OverwriteFileModal;
