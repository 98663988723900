import { FC } from 'react';
import {
  Box,
  List,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import FileIcon from '../../assets/file-icon.svg';
import ClassifierListItem from './ClassifierListItem';
import MirButton from '../../mir-styles/src/components/MirButton/MirButton';
import { FileType } from '../../providers/TestsProvider';
import Loading from '../../mir-styles/src/components/Loading/Loading';

interface ResultsProps {
  resultList: { Items: FileType[]; Count: number; ScannedCount: number } | undefined;
  checked: FileType[];
  isDownloading: boolean;
  showPreview: boolean;
  filesToApprove: any;
  previewFileName: string;
  handleToggle: (file: FileType) => void;
  downloadSeveralFiles: () => void;
  setShowPreview: (showPreview: boolean) => void;
  approveRejectFile: (filename: string, approve: boolean) => void;
  setPreviewFileName: (previewFileName: string) => void;
  setPreviewFileUiid: (previewFileUiid: string) => void;
}

const Results: FC<ResultsProps> = ({
  resultList,
  checked,
  isDownloading,
  showPreview,
  filesToApprove,
  previewFileName,
  handleToggle,
  downloadSeveralFiles,
  setShowPreview,
  approveRejectFile,
  setPreviewFileName,
  setPreviewFileUiid,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        p: '48px 100px',
        maxHeight: showPreview ? 'calc(100vh - 80px)' : '1109px',
      }}
    >
      <h2>Results</h2>
      <div style={{ marginTop: '28px' }}>
        <Typography sx={{ display: 'inline-block' }}>Accepted file type:</Typography>
        <img src={FileIcon} alt="File" className="file__img" />
        <Typography className="filetype__text">.csv</Typography>
      </div>
      <Typography sx={{ mt: '15px', mb: '28px', fontSize: '16px' }}>
        The files will be displayed in this list for 30 days. After this time they will be
        stored.
      </Typography>
      <List className="list__container">
        {!!resultList ? (
          resultList.Items.length > 0 ? (
            resultList.Items.map((result, idx) => {
              const labelId = `checkbox-list-label-${result.test_id}`;

              return (
                <ClassifierListItem
                  key={result.test_id}
                  labelId={labelId}
                  result={result}
                  checked={checked}
                  handleToggle={handleToggle}
                  showPreview={showPreview}
                  setShowPreview={setShowPreview}
                  approveRejectFile={approveRejectFile}
                  filesToApprove={filesToApprove}
                  previewFileName={previewFileName}
                  setPreviewFileName={setPreviewFileName}
                  setPreviewFileUiid={setPreviewFileUiid}
                />
              );
            })
          ) : (
            <div className={'emptyMsgAndLoading'}>
              <ListItem disablePadding>
                <ListItemText
                  className="pre-wrap-list-item"
                  primary={'No files have been uploaded yet'}
                />
              </ListItem>
            </div>
          )
        ) : (
          <div className={'emptyMsgAndLoading'}>
            <ListItem disablePadding>
              <ListItemIcon sx={{ pl: 'calc(50% - 20px)' }}>
                <Loading />
              </ListItemIcon>
            </ListItem>
          </div>
        )}
      </List>
      <div className="buttons_container">
        <MirButton
          maxWidth
          onClick={downloadSeveralFiles}
          isDisabled={checked.length === 0 || isDownloading}
          isLoading={isDownloading}
        >
          {isDownloading ? (
            <h3 className="buttons__text">DOWNLOADING</h3>
          ) : (
            <h3 className="buttons__text">DOWNLOAD</h3>
          )}
        </MirButton>
      </div>
    </Box>
  );
};

export default Results;
