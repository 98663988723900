import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useOktaAuth } from '@okta/okta-react';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';

import Loading from '../Loading/Loading';

import '../Logout/logout.css';

const LoginSSO = () => {
  const { oktaAuth } = useOktaAuth();
  const navigate = useNavigate();

  useEffect(() => {
    async function signIn() {
      if (!(await oktaAuth.isAuthenticated())) {
        await oktaAuth.signInWithRedirect();
      } else {
        navigate('/');
      }
    }
    signIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="logout">
      <Loading />
      <h1>Logging in to {process.env.REACT_APP_NAME} app...</h1>
    </div>
  );
};

export default LoginSSO;
