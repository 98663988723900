import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext, AuthType } from '../mir-styles/src/providers/AuthProvider';
import regionsService from '../services/Regions.service';

export interface RegionType {
  id: string;
  name: string;
}

export interface IRegions {
  region?: RegionType;
  regions?: RegionType[];
}

export type RegionsType = {
  regionsState: IRegions;
  updateRegionsState: (newRegionsState: IRegions) => void;
  getRegions: (token: string) => any;
};

interface Props {
  children: React.ReactNode;
}

export const RegionsContext = React.createContext<RegionsType | null>(null);

const RegionsProvider: React.FC<Props> = ({ children }) => {
  const { authContextState, updateAuthState } = React.useContext(AuthContext) as AuthType;
  const navigate = useNavigate();
  const [regionsState, setRegionsState] = React.useState<IRegions>({});

  const updateRegionsState = (newRegionsState: IRegions) => {
    setRegionsState({ ...regionsState, ...newRegionsState });
  };
  const notAuthorized = () => {
    updateAuthState({ ...authContextState, notAuthorized: true });
    navigate('/logout');
  };
  const getRegions = async (token: string) => {
    const resp = await regionsService.getRegions(token);
    if (resp.status === 200) return resp.data.regions;
    if (resp.response.status === 401) notAuthorized();
    return resp.data.regions;
  };

  return (
    <RegionsContext.Provider value={{ regionsState, updateRegionsState, getRegions }}>
      {children}
    </RegionsContext.Provider>
  );
};

export default RegionsProvider;
