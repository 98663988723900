import { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Outlet } from 'react-router-dom';
import { toRelativeUrl } from '@okta/okta-auth-js';

import Loading from '../Loading/Loading';
import { Box } from '@mui/material';

export const RequiredAuth = () => {
  const { oktaAuth, authState } = useOktaAuth();

  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(window.location.href, window.location.origin);
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }
  }, [authState, oktaAuth]);

  if (!authState || !authState?.isAuthenticated) {
    return (
      <Box
        sx={{
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: 'calc(100vh - 160px)',
        }}
      >
        <Loading />
      </Box>
    );
  }

  return <Outlet />;
};
