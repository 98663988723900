import * as React from 'react';
import s3TestsService from '../services/S3Tests.service';
import { AuthContext, AuthType } from '../mir-styles/src/providers/AuthProvider';
import { useNavigate } from 'react-router-dom';

export interface ResultListType {
  Items: FileType[];
  Count: number;
  ScannedCount: number;
}
export interface FileType {
  version: string;
  approved?: boolean;
  latest: number;
  versionId: string;
  createdAt: number;
  etag: string;
  extra_files: ExtraFileType[];
  test_id: string;
  file_size: number;
  testUUID?: string;
  reports?: ExtraFileType[];
}
export interface ExtraFileType {
  createdAt: number;
  etag: string;
  file_size: number;
  filename: string;
}
export interface ReportGroupType {
  test_id: string;
  createdAt: number;
  reports: ExtraFileType[];
  uuid: string;
}
export interface FileToOverwriteType {
  filename: string;
  signedURL?: string;
  overwrite?: boolean;
  exists?: boolean;
  uuid?: string;
}

export type TestsType = {
  resultListState: ResultListType | undefined;
  reportListState: ReportGroupType[] | undefined;
  updateResultList: (tests?: ResultListType) => void;
  updateResultListReports: (reports?: ReportGroupType[]) => void;
  getTestsFromBucket: (token: string, region: string) => any;
  getCsvFromBucket: (key: string, token: string, region: string) => any;
  putTxtFileInBucket: (file: File, signedUrl: string) => any;
  approveTests: (
    region: string,
    test_id: string,
    approved: boolean,
    token: string,
    filename?: string
  ) => any;
  getUploadUrl: (
    token: string,
    region: string,
    keys: { filename: string; overwrite?: boolean; exists?: boolean }[],
    contentType?: string
  ) => any;
  getPdfFromBucket: (key: string, token: string, region: string) => any;
};

interface Props {
  children: React.ReactNode;
}

export const TestsContext = React.createContext<TestsType | null>(null);

const TestsProvider: React.FC<Props> = ({ children }) => {
  const { authContextState, updateAuthState } = React.useContext(AuthContext) as AuthType;
  const navigate = useNavigate();

  const [resultListState, setResultListState] = React.useState<
    ResultListType | undefined
  >(undefined);
  const [reportListState, setReportListState] = React.useState<
    ReportGroupType[] | undefined
  >(undefined);

  const updateResultList = (newState?: ResultListType) => {
    if (!newState) {
      setResultListState(undefined);
    } else {
      setResultListState(newState);
    }
  };

  const updateResultListReports = (newState?: ReportGroupType[]) => {
    if (!newState) {
      setReportListState(undefined);
    } else {
      setReportListState(newState);
    }
  };

  const notAuthorized = () => {
    updateAuthState({ ...authContextState, notAuthorized: true });
    navigate('/logout');
  };

  const putTxtFileInBucket = async (file: File, signedUrl: string) => {
    const resp = await s3TestsService.putTxtFileInBucket(file, signedUrl);
    if (resp.status === 200) return resp;
    if (resp.response.status === 401) notAuthorized();
    return resp;
  };

  const getUploadUrl = async (
    token: string,
    region: string = 'us-east-1',
    keys: { filename: string; overwrite?: boolean; exists?: boolean }[] = [],
    contentType: string = 'text/plain'
  ) => {
    const resp = await s3TestsService.getUploadUrl(token, region, keys, contentType);
    if (resp.status === 200) return resp.data.objects;
    if (resp.response.status === 401) notAuthorized();
    return resp.data.objects;
  };

  const getTestsFromBucket = async (token: string, region: string) => {
    const resp = await s3TestsService.getTestsFromBucket(token, region);
    if (resp.status === 200) return resp.data;
    if (resp.response.status === 401) notAuthorized();
    return resp.data;
  };

  const getCsvFromBucket = async (
    key: string,
    token: string,
    region: string = 'us-east-1'
  ) => {
    const resp = await s3TestsService.getSignedS3Url(token, key, false, region);
    if (resp.status === 200) return resp.data.signedURL;
    if (resp.response.status === 401) notAuthorized();
    return resp.data.signedURL;
  };

  const approveTests = async (
    region: string = 'us-east-1',
    test_id: string,
    approved: boolean,
    token: string,
    filename: string = ''
  ) => {
    const resp = await s3TestsService.approveRejectFile(
      region,
      test_id,
      approved,
      token,
      filename
    );
    if (resp.status === 200) return resp.data;
    if (resp.response.status === 401) notAuthorized();
    return resp.data;
  };

  const getPdfFromBucket = async (
    key: string,
    token: string,
    region: string = 'us-east-1'
  ) => {
    const resp = await s3TestsService.getSignedS3Url(
      token,
      key,
      false,
      region,
      'text/csv',
      true
    );
    if (resp.status === 200) return resp.data.signedURL;
    if (resp.response.status === 401) notAuthorized();
    return resp.data.signedURL;
  };

  return (
    <TestsContext.Provider
      value={{
        resultListState,
        reportListState,
        updateResultList,
        updateResultListReports,
        getTestsFromBucket,
        getCsvFromBucket,
        putTxtFileInBucket,
        approveTests,
        getUploadUrl,
        getPdfFromBucket,
      }}
    >
      {children}
    </TestsContext.Provider>
  );
};

export default TestsProvider;
