import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import mirSentinelTheme from '../../styles/general';
import { Box } from '@mui/material';
import Loading from '../Loading/Loading';

interface MirButtonProps {
  isUppercase?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isOutlined?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
  buttonProps?: ButtonProps;
  maxWidth?: boolean;
  padding?: string;
}

const MirButton: React.FC<MirButtonProps> = ({
  isUppercase,
  onClick,
  isDisabled,
  isLoading,
  isOutlined,
  children,
  buttonProps,
  maxWidth,
  padding,
}) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <Button
        {...buttonProps}
        onClick={onClick}
        disabled={isDisabled}
        sx={{
          minWidth: maxWidth ? '100%' : '55px',
          color: isOutlined ? mirSentinelTheme.palette.primary.main : '#FFF',
          backgroundColor: isOutlined ? 'none' : mirSentinelTheme.palette.primary.main,
          border: isOutlined
            ? '2px solid'
            : `2px solid ${mirSentinelTheme.palette.primary.main}`,
          borderRadius: '2rem',
          '&:hover': {
            color: isOutlined ? mirSentinelTheme.palette.primary.light : 'none',
            border: isOutlined
              ? '2px solid'
              : `2px solid ${mirSentinelTheme.palette.primary.light}`,
            boxShadow: 'none',
            backgroundColor: isOutlined ? 'none' : mirSentinelTheme.palette.primary.light,
          },
          '&:disabled': {
            color: isOutlined ? '#99c2ff' : '#FFF',
            border: '2px solid #99c2ff',
            backgroundColor: isOutlined ? 'none' : '#99c2ff',
          },
          textTransform: !isUppercase ? undefined : 'none',
          padding: padding ? padding : '16px 52px',
        }}
      >
        {children}
      </Button>

      {isLoading && (
        <Box sx={loadingStyle}>
          <Loading />
        </Box>
      )}
    </Box>
  );
};

const loadingStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: '-20px',
  marginLeft: '-20px',
};

export default MirButton;
