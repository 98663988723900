import React, { useContext, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';

import { LoginCallback, useOktaAuth } from '@okta/okta-react';

import { RequiredAuth } from '../mir-styles/src/components/SecureRoute/SecureRoute';
import MirAppBar from '../mir-styles/src/components/AppBar/MirAppBar';
import {
  MirAppBarContext,
  miRAppBarType,
} from '../mir-styles/src/providers/MirAppBarProvider';
import MirClassifierPage from '../pages/MirClassifierPage';
import LoginSSO from '../mir-styles/src/components/LoginSSO/LoginSSO';
import Loading from '../mir-styles/src/components/Loading/Loading';
import ErrorPage, {
  ErrorRedirect,
} from '../mir-styles/src/components/ErrorPage/ErrorPage';
import Logout from '../mir-styles/src/components/Logout/Logout';
import { AuthContext, AuthType } from '../mir-styles/src/providers/AuthProvider';
import { RegionsContext, RegionsType } from '../providers/RegionsProvider';
import { Box } from '@mui/material';

const AppRoutes = () => {
  const { mirAppBarState, updateMirAppbar } = useContext(
    MirAppBarContext
  ) as miRAppBarType;
  const { oktaAuth, authState } = useOktaAuth();
  const { authContextState } = useContext(AuthContext) as AuthType;
  const { regionsState, updateRegionsState, getRegions } = useContext(
    RegionsContext
  ) as RegionsType;
  const navigate = useNavigate();

  useEffect(() => {
    oktaAuth
      .getUser()
      .then((info) => {
        updateMirAppbar({
          logout: _handleLogout,
          user: info,
          title: 'Classifier',
        });
        getRegions(oktaAuth.getAccessToken() || '').then((res: any) => {
          updateRegionsState({
            regions: res,
          });
        });
      })
      .catch((err) => {
        console.error(err);
        // console.log('error get user:', err.xhr.status && err.xhr.status);
      });
  }, [authState?.isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  const _handleLogout = () => {
    navigate('/logout');
    // window.opener = null;
    // window.open('', '_self');
    // window.close();
    // oktaAuth.tokenManager.getTokens().then(async (tokens) => {
    //   await oktaAuth.revokeAccessToken(tokens.accessToken)
    //   await oktaAuth.revokeRefreshToken(tokens.refreshToken)
    //   oktaAuth.tokenManager.clear();
    // })
    // await oktaAuth.signOut({ clearTokensBeforeRedirect: true, postLogoutRedirectUri: process.env.REACT_APP_OKTA_URL })
  };

  return (
    <>
      <MirAppBar
        title={mirAppBarState.title}
        logout={mirAppBarState.logout}
        user={mirAppBarState.user}
        region={regionsState.region}
        updateRegionsState={updateRegionsState}
        showRegionSelector={true}
        regions={regionsState.regions}
      />
      <Routes>
        <Route path="/login/sso" element={<LoginSSO />} />
        <Route
          path="/logout"
          element={<Logout notAuthorized={authContextState.notAuthorized} />}
        />
        <Route
          path="/login/callback"
          element={
            <LoginCallback
              loadingElement={
                <Box
                  sx={{
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 'calc(100vh - 160px)',
                  }}
                >
                  <Loading />
                </Box>
              }
              errorComponent={ErrorRedirect}
            />
          }
        />
        <Route
          path="/error"
          element={<ErrorPage title={process.env.REACT_APP_NAME || ''} />}
        />
        <Route path="/" element={<RequiredAuth />}>
          <Route path="/" element={<MirClassifierPage />} />
        </Route>
      </Routes>
    </>
  );
};

export default AppRoutes;
