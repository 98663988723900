import { FC } from 'react';
import {
  Box,
  List,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import MirButton from '../../mir-styles/src/components/MirButton/MirButton';
import FileIcon from '../../assets/file-icon.svg';
import ReporterListItem from './ReporterListItem';
import Loading from '../../mir-styles/src/components/Loading/Loading';
import { ReportGroupType } from '../../providers/TestsProvider';

interface ResultsProps {
  resultList: ReportGroupType[] | undefined;
  checked: ReportGroupType[];
  isDownloading: boolean;
  handleToggle: (value: ReportGroupType) => void;
  downloadSeveralFiles: () => void;
}

const Results: FC<ResultsProps> = ({
  resultList,
  checked,
  isDownloading,
  handleToggle,
  downloadSeveralFiles,
}) => {
  return (
    <Box sx={boxStyle}>
      <h2>Reports</h2>
      <div style={{ margin: '28px 0px' }}>
        <Typography sx={{ display: 'inline-block' }}>Accepted file type:</Typography>
        <img src={FileIcon} alt="File" className="file__img" />
        <Typography className="filetype__text">.pdf</Typography>
      </div>
      <List className="list__container">
        {!!resultList ? (
          resultList.length > 0 ? (
            resultList.map((result, idx) => {
              const labelId = `checkbox-list-label-${result.test_id}`;

              return (
                <ReporterListItem
                  key={idx}
                  labelId={labelId}
                  result={result}
                  checked={checked}
                  handleToggle={handleToggle}
                />
              );
            })
          ) : (
            <div className={'emptyMsgAndLoading'}>
              <ListItem disablePadding>
                <ListItemText
                  className="pre-wrap-list-item"
                  primary={'No files have been uploaded yet'}
                />
              </ListItem>
            </div>
          )
        ) : (
          <div className={'emptyMsgAndLoading'}>
            <ListItem disablePadding>
              <ListItemIcon sx={{ paddingLeft: 'calc(50% - 20px)' }}>
                <Loading />
              </ListItemIcon>
            </ListItem>
          </div>
        )}
      </List>
      <div className="buttons_container">
        <MirButton
          maxWidth
          onClick={downloadSeveralFiles}
          isDisabled={checked.length === 0 || isDownloading}
          isLoading={isDownloading}
        >
          {isDownloading ? (
            <h3 className="buttons__text">DOWNLOADING</h3>
          ) : (
            <h3 className="buttons__text">DOWNLOAD</h3>
          )}
        </MirButton>
      </div>
    </Box>
  );
};

const boxStyle = {
  display: 'flex',
  flexDirection: 'column',
  p: '48px 100px',
  maxHeight: '1109px',
};

export default Results;
