import React from 'react';
import { CircularProgress } from '@mui/material';

const Loading: React.FC = () => {
  return (
    <>
      <svg style={{ height: '0', width: '0' }}>
        <linearGradient id="my_gradient">
          <stop offset="0%" stopColor="#0066FF" />
          <stop offset="100%" stopColor="#f6f9fb" />
        </linearGradient>
      </svg>
      <CircularProgress sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} />
    </>
  );
};

export default Loading;
