import { FC } from 'react';
import { Chip } from '@mui/material';

/*
    ACCEPTED TYPES:
    -approved
    -rejected
    -new
*/

interface ChipProps {
  type: string;
}

const StatusChip: FC<ChipProps> = ({ type }) => {
  let label: string = '';
  let lightColor: string = '';
  let darkColor: string = '';

  switch (type) {
    case 'approved':
      label = 'Approved';
      lightColor = 'var(--MirPositive01)';
      darkColor = 'var(--MirPositive03)';
      break;
    case 'rejected':
      label = 'Rejected';
      lightColor = 'var(--MirNegative01)';
      darkColor = 'var(--MirNegative03)';
      break;
    case 'new':
      label = 'New!';
      lightColor = 'var(--MirInfo01)';
      darkColor = 'var(--MirInfo03)';
      break;
  }

  return (
    <Chip
      label={label}
      variant="outlined"
      size="small"
      sx={{
        borderRadius: '4px',
        border: `1.5px solid ${darkColor}`,
        backgroundColor: lightColor,
        fontSize: '12px',
        fontWeight: 600,
        color: darkColor,
        mr: '10px',
      }}
    />
  );
};

export default StatusChip;
