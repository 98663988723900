import React, { useEffect } from 'react';

import { UserClaims } from '@okta/okta-auth-js';

import { Autocomplete, TextField, useMediaQuery } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';

import mirLogo from './../../assets/mir_logo.png';
import mirSentinelTheme from '../../styles/general';

interface miRAppBarProps {
  user?: UserClaims;
  imageSrc?: string;
  title?: string;
  region?: { id: string; name: string };
  showRegionSelector?: boolean;
  regions?: { id: string; name: string }[];
  logout?: () => void;
  login?: () => void;
  updateRegionsState?: (upd: any) => void;
}

const MirAppBar: React.FC<miRAppBarProps> = ({
  user,
  imageSrc,
  title,
  region,
  showRegionSelector,
  regions,
  logout,
  login,
  updateRegionsState,
}) => {
  const smallScreen = useMediaQuery(mirSentinelTheme.breakpoints.down('sm'));

  useEffect(() => {
    regions && updateRegionsState!({ region: regions![0] });
  }, [regions]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AppBar position="static" color="transparent" sx={{ px: '50px' }}>
      <Toolbar disableGutters sx={{ height: '70px' }}>
        <img
          src={!!imageSrc ? imageSrc : mirLogo}
          style={{ height: '45px' }}
          className="App-logo"
          alt="logo"
        />
        <Typography variant="h4" noWrap component="a" sx={titleStyle}>
          {!!title ? title : ''}
        </Typography>

        <Box sx={{ flexGrow: 1 }} />

        {showRegionSelector && (
          <>
            {' '}
            <Typography noWrap sx={regionTextStyle}>
              Selected region:{' '}
            </Typography>
            <Autocomplete
              value={region ? region : regions ? regions[0] : [{ id: '', name: '' }]}
              options={regions ? regions : []}
              getOptionLabel={(option: any) => (option.name ? option.name : '')}
              onChange={(event: any, newValue: any) => {
                if (!!newValue)
                  !!updateRegionsState && updateRegionsState({ region: newValue });
              }}
              inputValue={region ? region.name : ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <LocationOnRoundedIcon
                        sx={{ color: mirSentinelTheme.palette.primary.dark }}
                      />
                    ),
                  }}
                  sx={{
                    '& fieldset': {
                      border: `3px solid ${mirSentinelTheme.palette.primary.main} !important`,
                      borderRadius: '6px',
                    },
                  }}
                  size="small"
                />
              )}
              sx={autocompleteStyle}
              disableClearable
            />
          </>
        )}

        <Box sx={{ flexGrow: 0 }}>
          {!!user ? (
            <Tooltip title={user?.name || 'User'}>
              <IconButton sx={avatarBtnStyle}>
                <Avatar
                  alt={user?.name}
                  sx={{ bgcolor: mirSentinelTheme.palette.primary.main, p: 0 }}
                >
                  {user?.name?.match(/\b\w/g)?.join('')}
                </Avatar>
              </IconButton>
            </Tooltip>
          ) : null}
          {!!logout && !smallScreen ? (
            <Button
              variant="text"
              sx={{ ml: { xs: '5px', md: '20px' } }}
              onClick={logout}
            >
              Logout
            </Button>
          ) : null}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

const titleStyle = {
  marginLeft: '30px',
  display: { xs: 'none', md: 'flex' },
  fontWeight: 700,
  textDecoration: 'none',
};

const regionTextStyle = {
  display: { xs: 'none', md: 'flex' },
  color: '#0066ff',
  fontWeight: 600,
  fontSize: '14px',
};

const autocompleteStyle = {
  minWidth: { xs: '76px', sm: '150px', md: '235px' },
  color: '#04142C',
  mr: '32px',
  ml: '8px',
};

const avatarBtnStyle = {
  p: 0,
  cursor: 'default',
  display: { xs: 'none', sm: 'none', md: 'initial' },
};

export default MirAppBar;
