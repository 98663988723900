import { FC } from 'react';
import {
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import React from 'react';
import Loading from '../../mir-styles/src/components/Loading/Loading';

interface PreviewSectionProps {
  filesPreview: any[];
  tableColumns: any[];
  isUploading: boolean;
  previewFileName: string;
  previewFileUiid: string;
  setShowPreview: (show: boolean) => any;
  setFilesPreview: (filesPrev: any[]) => void;
  setTableColumns: (tableColumns: any[]) => void;
}

const PreviewSection: FC<PreviewSectionProps> = ({
  filesPreview,
  tableColumns,
  isUploading,
  previewFileName,
  previewFileUiid,
  setShowPreview,
  setFilesPreview,
  setTableColumns,
}) => {
  const [hover, setHover] = React.useState(false);
  return (
    <>
      <Divider orientation="vertical" />
      <Grid item sm={5.98} md={3.98} sx={gridStyle}>
        <div className="preview__container ">
          <div className="closePreview__container">
            <Button
              sx={closeButtonStyle}
              onMouseOver={() => {
                setHover(true);
              }}
              onMouseOut={() => {
                setHover(false);
              }}
              onClick={() => {
                setShowPreview(false);
                setFilesPreview([]);
                setTableColumns([]);
                setTimeout(() => {
                  window.scrollTo({
                    top: 650,
                    behavior: 'smooth',
                  });
                });
              }}
            >
              <CloseRoundedIcon
                sx={{
                  color: hover ? '#438EFF ' : 'var(--MirNeutral06)',
                  transition: '.2s cubic-bezier(.785,.135,.15,.86) 0s',
                  transform: hover ? 'rotate(90deg)' : 'rotate(-90deg)',
                  height: '28px',
                  width: '28px',
                }}
              />
            </Button>
          </div>
          <div className="bodyPreview__container">
            <h2>Preview</h2>
            <h3 className="filename__preview">
              {previewFileName?.replace(previewFileUiid, '')}
            </h3>
            {isUploading ? (
              <div className="circularProgress__container">
                <Loading />
              </div>
            ) : (
              <div className="table__container">
                <Table>
                  <TableHead>
                    <TableRow>
                      {tableColumns &&
                        tableColumns.length > 1 &&
                        tableColumns.map((column, idx) => (
                          <TableCell
                            key={idx}
                            sx={{
                              paddingLeft: idx === 0 ? '40px' : '16px',
                            }}
                          >
                            {column}
                          </TableCell>
                        ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filesPreview &&
                      filesPreview.length > 1 &&
                      filesPreview.map((f, idx) => (
                        <TableRow
                          key={idx}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {`${idx + 1} -  ${f['ID']}`}
                          </TableCell>
                          <TableCell>{f['Test Class']}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </div>
            )}
          </div>
        </div>
      </Grid>
    </>
  );
};

const closeButtonStyle = {
  minWidth: '24px',
  width: '35px',
  height: '35px',
  borderRadius: '50px',
};

const gridStyle = {
  backgroundColor: 'var(--MirNeutral02)',
  maxHeight: 'calc(100vh - 80px)',
};

export default PreviewSection;
