import React, { useEffect, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import './ErrorPage.css';

export const ErrorRedirect = (e: any) => {
  const { error } = e;

  const getErrorType = (error: any) => {
    switch (error.toString()) {
      case 'OAuthError: User is not assigned to the client application.':
        return 'NOT_AUTHORIZED';
      default:
        return 'NOT_AUTHORIZED';
    }
  };

  return <Navigate to={'/error?error=' + getErrorType(error)} />;
};

interface ErrorPageProps {
  title: string;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ title }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const [error, setError] = useState('');

  useEffect(() => {
    const errorParam = searchParams.get('error');
    alert(errorParam);
    if (!!errorParam && errorParam === 'NOT_AUTHORIZED') {
      setError(
        'You are not authorized to use this application. Please contact the administrator if you think this is an error'
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <>
      <div className="titleContainer">
        <h1>{title}</h1>
      </div>
      <div className="errorContainer">
        <h1>Uh oh. There seems to have been an error</h1>
        {!!error && <h3>{error}</h3>}
      </div>
    </>
  );
};

export default ErrorPage;
