import axios from 'axios';

class RegionsService {
  async getRegions(token: string): Promise<any> {
    const baseUrl = `${process.env.REACT_APP_API_HOST}/getRegions`;
    const resp = await axios
      .post(
        baseUrl,
        {},
        {
          headers: {
            token,
          },
        }
      )
      .then((resp) => {
        return resp;
      })
      .catch((resp) => {
        return resp;
      });
    return resp;
  }
}

const regionsService = new RegionsService();
export default regionsService;
