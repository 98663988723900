import { UserClaims } from '@okta/okta-auth-js';
import * as React from 'react';

export interface ImiRAppBar {
  user?: UserClaims;
  logout?: () => void;
  login?: () => void;
  imageSrc?: string;
  title?: string;
}

export type miRAppBarType = {
  mirAppBarState: ImiRAppBar;
  updateMirAppbar: (miRAppBar: ImiRAppBar) => void;
};

interface Props {
  children: React.ReactNode;
}

export const MirAppBarContext = React.createContext<miRAppBarType | null>(null);

const MiRAppBarProvider: React.FC<Props> = ({ children }) => {
  const [mirAppBarState, setMirAppBarState] = React.useState<ImiRAppBar>({});

  const updateMirAppbar = (newMirAppBarState: ImiRAppBar) => {
    setMirAppBarState({ ...mirAppBarState, ...newMirAppBarState });
  };

  return (
    <MirAppBarContext.Provider value={{ mirAppBarState, updateMirAppbar }}>
      {children}
    </MirAppBarContext.Provider>
  );
};

export default MiRAppBarProvider;
