import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { Dropzone, FileItem, FileValidated } from '@dropzone-ui/react';
import FileIcon from '../../assets/file-icon.svg';
import MirButton from '../../mir-styles/src/components/MirButton/MirButton';

interface UploadFilesProps {
  files: FileValidated[];
  isUploading: boolean;
  updateFiles: (incomingFiles: FileValidated[]) => void;
  uploadFiles: () => void;
  onDelete: (id: string | number | undefined) => void;
}

const UploadFiles: FC<UploadFilesProps> = ({
  files,
  isUploading,
  updateFiles,
  uploadFiles,
  onDelete,
}) => {
  return (
    <Box
      sx={{
        p: '40px 100px',
        height: '662px',
      }}
    >
      <h2>File upload</h2>
      <div style={{ margin: '28px 0px' }}>
        <Typography sx={{ display: 'inline-block' }}>Accepted file type:</Typography>
        <img src={FileIcon} alt="File" className="file__img" />
        <Typography className="filetype__text">.txt</Typography>
      </div>

      <div style={{ height: '365px' }}>
        <Dropzone
          onChange={updateFiles}
          value={files}
          accept=".txt"
          label="Drop your files here to upload or "
          disableScroll
        >
          {files.map((file: any, idx) => (
            <FileItem key={idx} {...file} preview onDelete={onDelete} />
          ))}
        </Dropzone>
      </div>
      <div className="buttons_container">
        <MirButton
          maxWidth
          onClick={uploadFiles}
          isDisabled={files.length === 0 || isUploading}
          isLoading={isUploading}
        >
          {isUploading ? (
            <h3 className="buttons__text">UPLOADING</h3>
          ) : (
            <h3 className="buttons__text">UPLOAD</h3>
          )}
        </MirButton>
      </div>
    </Box>
  );
};

export default UploadFiles;
