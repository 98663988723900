export function padTo2Digits(num: number): string {
  return num.toString().padStart(2, '0');
}

/**
 * Formats a Date with the format of YYYY-MM-DD, hh:mm:ss
 * @param date
 * @param includeHour
 * @returns string - Date with or without hours
 */
export function formatDate(date: Date, includeHour = true): string {
  const dateShort = [
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join('/');

  const hours =
    ', ' +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds()),
    ].join(':');

  if (includeHour) {
    return dateShort + hours;
  } else {
    return dateShort;
  }
}
