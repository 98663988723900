import { createTheme } from '@mui/material/styles';

const mirSentinelTheme = createTheme({
  palette: {
    primary: {
      main: '#0066FF',
      dark: '#0C387A',
      light: '#408CFF',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#F6F9FB',
        },
      },
    },
  },
});

export default mirSentinelTheme;
