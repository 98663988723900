import axios from 'axios';

class S3TestsService {
  async getSignedS3Url(
    token: string,
    key: string,
    isInput: boolean = true,
    region: string = 'us-east-1',
    contentType: string = 'text/plain',
    isReport: boolean = false
  ) {
    const data = {
      key,
      region,
      contentType,
      isInput,
      isReport,
    };

    const baseUrl = `${process.env.REACT_APP_API_HOST}/getSignedUrl`;
    const resp = await axios
      .post(baseUrl, data, {
        headers: {
          token,
        },
      })
      .then((resp) => {
        return resp;
      })
      .catch((resp) => {
        return resp;
      });

    return resp;
  }

  async getUploadUrl(
    token: string,
    region: string = 'us-east-1',
    keys: { filename: string; overwrite?: boolean; exists?: boolean }[] = [],
    contentType: string = 'text/plain'
  ): Promise<any> {
    const baseUrl = `${process.env.REACT_APP_API_HOST}/getUploadUrl`;
    const data = {
      region,
      contentType,
      keys,
    };
    const resp = await axios
      .post(baseUrl, data, {
        headers: {
          token,
        },
      })
      .then((resp) => {
        return resp;
      })
      .catch((resp) => {
        return resp;
      });
    return resp;
  }

  async putTxtFileInBucket(file: File, signedUrl: string): Promise<any> {
    const resp = await fetch(signedUrl, {
      method: 'PUT',
      body: file,
    })
      .then((resp) => {
        return resp;
      })
      .catch((resp) => {
        return resp;
      });
    return resp;
  }

  async getTestsFromBucket(token: string, region: string): Promise<any> {
    const baseUrl = `${process.env.REACT_APP_API_HOST}/getTests`;
    const resp = await axios
      .post(
        baseUrl,
        {
          region,
        },
        {
          headers: {
            token,
          },
        }
      )
      .then((resp) => {
        return resp;
      })
      .catch((resp) => {
        return resp;
      });
    return resp;
  }

  async approveRejectFile(
    region: string = 'us-east-1',
    test_id: string,
    approved: boolean,
    token: string,
    filename: string = ''
  ): Promise<any> {
    const baseUrl = `${process.env.REACT_APP_API_HOST}/approveTest`;
    const data = {
      region,
      filename,
      test_id,
      approved,
    };
    const resp = await axios
      .post(baseUrl, data, {
        headers: {
          token,
        },
      })
      .then((resp) => {
        return resp;
      })
      .catch((resp) => {
        return resp;
      });
    return resp;
  }
}

const s3TestsService = new S3TestsService();
export default s3TestsService;
