import { useCallback, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';

import config from './config';
import Routes from './components/Routes';
import './App.css';
import MiRAppBarProvider from './mir-styles/src/providers/MirAppBarProvider';
import TestsProvider from './providers/TestsProvider';
import { Alert, Snackbar } from '@mui/material';
import {
  AlertMsgContext,
  AlertMsgType,
} from './mir-styles/src/providers/AlertMsgProvider';
import RegionsProvider from './providers/RegionsProvider';

const oktaAuth = new OktaAuth(config.oidc);

const App = () => {
  const { setAlertMsgOpen, alertMsgState } = useContext(AlertMsgContext) as AlertMsgType;
  const navigate = useNavigate();

  const restoreOriginalUri = useCallback(
    (_: any, originalUri = '/') => {
      const url = toRelativeUrl(originalUri, globalThis.location.origin);
      navigate(url, { replace: true });
    },
    // Allow "stale" navigate references as originalUri will be an absolute URL.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    return () => {
      oktaAuth.options.restoreOriginalUri = undefined;
    };
  }, []);

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <div className="App">
        <MiRAppBarProvider>
          <RegionsProvider>
            <TestsProvider>
              <Snackbar
                open={alertMsgState.open}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                autoHideDuration={6000}
                onClose={() => setAlertMsgOpen(false)}
                sx={{
                  height: '48px',
                  borderRadius: '8px',
                  fontSize: '14px',
                }}
              >
                <Alert
                  severity={alertMsgState.severity}
                  onClose={() => setAlertMsgOpen(false)}
                  sx={{
                    color: 'var(--MirNeutral08)',
                    backgroundColor:
                      alertMsgState.severity === 'success'
                        ? 'var(--MirPositive02)'
                        : alertMsgState.severity === 'error'
                        ? 'var(--MirNegative02)'
                        : alertMsgState.severity === 'warning'
                        ? 'var(--MirWarning02)'
                        : 'var(--MirInfo02)',
                  }}
                >
                  {alertMsgState.message}
                </Alert>
              </Snackbar>
              <Routes />
            </TestsProvider>
          </RegionsProvider>
        </MiRAppBarProvider>
      </div>
    </Security>
  );
};

export default App;
